var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth" }, [
    _c("div", { staticClass: "auth-wrapper" }, [
      _c(
        "div",
        { staticClass: "card mw468" },
        [
          _c(
            "b-form",
            {
              staticClass: "card__content pt-60 pb-52 px-56",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.resetPassword($event)
                }
              }
            },
            [
              _c("img", {
                staticClass: "d-block w-100 mw170 mx-auto mb-80 mb-mb-60",
                attrs: { src: require("@/assets/svg/logo_full.svg") }
              }),
              _c(
                "b-form-group",
                {
                  staticClass: "mb-40 text-gray-600",
                  attrs: {
                    id: "email-field",
                    label: "New Password",
                    "label-for": "password"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "email",
                      type: "password",
                      placeholder: "Enter New Password",
                      trim: ""
                    },
                    model: {
                      value: _vm.form.password,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  staticClass: "mb-40 text-gray-600",
                  attrs: {
                    id: "email-field",
                    label: "Confirm Password",
                    "label-for": "confirm_password"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "confirm_password",
                      type: "password",
                      placeholder: "Confirm New Password",
                      trim: ""
                    },
                    model: {
                      value: _vm.form.confirmPassword,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "confirmPassword", $$v)
                      },
                      expression: "form.confirmPassword"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "w-100 mb-40",
                  attrs: { type: "submit", variant: "primary", size: "lg" }
                },
                [_vm._v("Submit")]
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }