<template>
	<div class="auth">
		<div class="auth-wrapper">
			<div class="card mw468">
				<b-form @submit.prevent="resetPassword" class="card__content pt-60 pb-52 px-56">
					<img src="@/assets/svg/logo_full.svg" class="d-block w-100 mw170 mx-auto mb-80 mb-mb-60" />
					<b-form-group
					id="email-field"
					label="New Password"
					label-for="password"
					class="mb-40 text-gray-600"
					>
					<b-form-input
					id="email"
					type="password"
					v-model="form.password"
					placeholder="Enter New Password"
					trim
					></b-form-input></b-form-group>

					<b-form-group
					id="email-field"
					label="Confirm Password"
					label-for="confirm_password"
					class="mb-40 text-gray-600"
					>
					<b-form-input
					id="confirm_password"
					type="password"
					v-model="form.confirmPassword"
					placeholder="Confirm New Password"
					trim
					></b-form-input></b-form-group>

					<b-button type="submit" variant="primary" size="lg" class="w-100 mb-40">Submit</b-button>
				</b-form>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ResetPassword",
	title: "Shyplite - Reset Password",
	data() {
		return {
			form: { password: "", confirmPassword: "", profileToken:"", emailID:"" },
			reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
			state: null,
		};
	},
	computed: {},
	mounted() {
		this.form.profileToken = this.$route.query.profileToken
		this.form.emailID = this.$route.query.emailID
	},
	methods: {
		async resetPassword() {
			try {
				const res = await this.axios.post("/resetprofilepassword", this.form);
				if (res.data.success) {
					this.popToast("booked", "Success!", res.data.message);
					setTimeout(() => {
						this.$router.push("/cod");
					}, 2000);
				} else {
					this.popToast("failed", "Failed!", res.data.message);
				}
			} catch (error) {
				console.log(error)
			}
		},

	},
};
</script>
